import React, { useState } from 'react'
import { useEffect } from 'react'
import ReactCompareImage from 'react-compare-image'
import Header from '../partials/Header'
import pp20200509t from '../images/progress/2020-05-09-Top.jpg'
import pp20200704t from '../images/progress/2020-07-04-Top.jpg'
import pp20200816t from '../images/progress/2020-08-16-Top.jpg'
import pp20201130t from '../images/progress/2020-11-30-Top.jpg'
import pp20210208t from '../images/progress/2021-02-08-top.jpg'
import pp20210208tt from '../images/progress/2021-02-08-top-tilt.jpg'

const images = [
  {
    date: '2020-05-09',
    url: pp20200509t,
  },
  {
    date: '2020-07-04',
    url: pp20200704t,
  },
  {
    date: '2020-08-16',
    url: pp20200816t,
  },
  {
    date: '2020-11-30',
    url: pp20201130t,
  },
  {
    date: '2021-02-08',
    url: pp20210208tt,
  },
]

export default function ProgressPhotos(props) {
  const [{ url: leftImageUrl, ...leftImage }, setLeftImage] = useState(images[0])
  const [{ url: rightImageUrl, ...rightImage }, setRightImage] = useState(images[images.length - 1])

  return (
    <>
      <Header />
      <section>
        <header className="text-center p-4">
          <h1>Hair Compare</h1>
          <p>
            Use the dropdowns to change the displayed photos and use the slider to change where the
            images overlay.
          </p>
          <p className="italic text-sm text-gray-500">
            Tip: If you want to see one image at a time, just move the slider all the way to one
            side.
          </p>
        </header>
        <div className="mx-auto max-w-3xl overflow-hidden">
          <div className="flex justify-between">
            <ImageSelect image={leftImage} onChange={setLeftImage} />
            <ImageSelect image={rightImage} onChange={setRightImage} />
          </div>
          <ReactCompareImage
            leftImage={leftImageUrl}
            rightImage={rightImageUrl}
          ></ReactCompareImage>
        </div>
      </section>
    </>
  )
}

const ImageSelect = ({ image, onChange }) => (
  <div className="w-1/2 mb-8 text-center">
    <select
      className="p-2 bg-yellow-200 rounded-md"
      onChange={({ target: { value } }) => onChange(images.find(({ date }) => date === value))}
      defaultValue={images.find(({ date }) => date === image.date)?.date}
    >
      {images.map(({ date }, index) => (
        <option key={date}>{date}</option>
      ))}
    </select>
  </div>
)
