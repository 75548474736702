import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { youtube, reddit } from '../data/social.json'

export default function Header({ children = '' }) {
  const allSite = useStaticQuery(graphql`
    {
      allSite {
        edges {
          node {
            siteMetadata {
              description
              title
            }
          }
        }
      }
    }
  `)

  const { title } = allSite.allSite.edges[0].node.siteMetadata

  return (
    <header className="container mx-auto mb-4 p-4">
      <div className="float-right text-4xl pl-2">
        <a href={reddit} target="_blank" className="mr-2 text-yellow-600">
          <FontAwesomeIcon icon={['fab', 'reddit']} />
        </a>
        <a href={youtube} target="_blank" className="text-yellow-600">
          <FontAwesomeIcon icon={['fab', 'youtube']} />
        </a>
      </div>
      <h1 className="text-3xl md:text-4xl mb-1">
        <Link className="no-underline text-yellow-900" to="/">
          {title}
        </Link>
        <span className="px-2" role="img" aria-label="Sunglasses smiley emoji">
          🙄
        </span>
      </h1>
      {children}
    </header>
  )
}
